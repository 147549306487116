import path from 'path';

import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import { filterMarkets, getCanonicalUrl, getHrefLanguages } from '@hultafors/shared/helpers';

import { datoApi, markets } from '@hultafors/hultaforsgroup/api';
import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import {
  GlobalFields,
  LandingPageQuery,
  NewsPageListingFragment,
  PageProps,
} from '@hultafors/hultaforsgroup/types';

import { prodDomains } from '../next.domains';

const ArticleList = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then(
    (module) => module.ArticleList,
  ),
);
const MoreNewsWrapper = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then(
    (module) => module.MoreNewsWrapper,
  ),
);
const Page = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((module) => module.Page),
);

const ContentArea = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.ContentArea),
);
const DynamicContent = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then(
    (mod) => mod.DynamicContent,
  ),
);
const Hero = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.Hero),
);
const OurBrandsBlock = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then(
    (mod) => mod.OurBrandsBlock,
  ),
);

// Needed for standalone build, for some reason
path.resolve('./next.config.js');

const NUMBER_OF_ARTICLES = 5;

interface LandingPageProps extends PageProps {
  content: LandingPageQuery['landingPage'];
  allNewsPages: NewsPageListingFragment[];
  newsListPage: LandingPageQuery['newsListPage'];
}

export const getStaticProps: GetStaticProps<LandingPageProps> = async ({
  locale,
}) => {
  const revalidate = 300; // 5 minutes
  try {
    const dato = datoApi(locale);
    const canonical = getCanonicalUrl(
      locale,
      prodDomains,
      'www.hultaforsgroup.com',
    );

    const {
      data: {
        landingPage: content,
        allNewsPages,
        newsListPage,
        ...globalFields
      },
    } = await dato.getLandingPage(NUMBER_OF_ARTICLES);

    const hrefLanguages = getHrefLanguages(
      null,
      filterMarkets(markets, 'HULTAFORSGROUP'),
      content?.pageLocales,
    );

    const props: LandingPageProps = {
      content,
      allNewsPages,
      newsListPage,
      ...(globalFields as unknown as GlobalFields), canonical,
      hrefLanguages,
    };

    return { props, revalidate };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
      revalidate,
    };
  }
};

const LandingPage: NextPage<LandingPageProps> = ({
  canonical,
  content,
  allNewsPages,
  newsListPage,
  hrefLanguages,
}) => {
  const { isEnabled } = useGlobal();

  return (
    <Page
      seo={content.seo}
      testId="landing-page"
      canonical={canonical}
      hrefLanguages={hrefLanguages}
    >
      {content?.contentArea && isEnabled('landingPageContentArea') && (
        <ContentArea content={content.contentArea} />
      )}

      {!isEnabled('landingPageContentArea') && (
        <>
          <Hero
            title={content?.heroTitle}
            image={content?.imageDesktop}
            imageMobile={content?.imageMobileNew}
            imageTablet={content?.imageTablet}
            description={content?.heroDescription}
            priority
          />

          <OurBrandsBlock
            title={content?.brandsHeader}
            introText={content?.introText}
            brands={content?.brands}
          />

          <DynamicContent
            // Overriding padding with zero since OurBrands and MoreNewsWrapper
            // has their own padding.
            padding="0 inherit"
            content={content?.dynamicContent}
          />
          <MoreNewsWrapper
            title={newsListPage?.moreNewsHeader}
            CTAText={newsListPage?.moreNewsCta}
            CTAUrl="/news"
            bigTitle
          >
            <ArticleList newsList={allNewsPages} isNewsArticle />
          </MoreNewsWrapper>
        </>
      )}
    </Page>
  );
};

export default LandingPage;
